<template>
	<div class="map">
		<b-table :items="items" :fields="fields" class="map__custom-table pb-5" sticky-header>
			<template #cell(name)="data">
				<div class="map__name-field d-flex align-items-center">
					<img v-if="data.item.file_type == 'video'" :src="data.item.video_thumbnail" :alt="data.item.name" class="map__img">
					<div v-else-if="data.item.file_type == 'file_group'" class="map__folder d-flex align-items-center justify-content-center">
						<img src="@/assets/images/icons/ic_stack-file.svg" alt="Folder">
					</div>
					<img v-else :src="data.item.src" :alt="data.item.name" class="map__img">
					<div class="name--detail d-flex flex-column justify-content-center">
						<p class="m-0" :title="data.item.name">{{ truncatedText(data.item.name) }}</p>
						<p v-if="data.item.file_type == 'file_group'" class="map__small m-0">{{ data.item.total_file ? data.item.total_file : '-' }} files</p>
					</div>
				</div>
			</template>

			<template #cell(file_size)="data">
				<p class="m-0 map__stc">{{ data.item.file_size ? convertToBytes(data.item.file_size) : '-' }}</p>
			</template>

			<template #cell(file_type)="data">
				<p class="m-0 map__stc">{{ data.item.file_type == 'file_group' ? '-' : data.item.file_type }}</p>
			</template>

			<template #cell(createdAt)="data">
				<p class="m-0 map__stc">{{ data.item.createdAt ? getDate(data.item.createdAt) : '-' }}</p>
			</template>

			<template #cell(action)="data">
				<div class="dropdown map__dropdown">
					<button class="map__button-action btn p-0" type="button" data-toggle="dropdown" aria-expanded="false">
						<font-awesome-icon icon="fa-solid fa-ellipsis" class="map__ba-icon"/>
					</button>
					<div class="dropdown-menu dropdown-menu-right">
						<a class="dropdown-item text-white px-2 cursor-pointer" @click.stop="downloadImage(data.item.id, data.item.src, data.item.name, data.item.file_type)">{{ $t('btn-download') }}</a>
						<a v-if="data.item.file_type != 'file_group'" class="dropdown-item text-white px-2 cursor-pointer" @click="$emit('click:rename-asset', { id : data.item.id , name : data.item.name })">{{ $t('rename') }}</a>
						<a class="dropdown-item text-white px-2 cursor-pointer" @click="$emit('click:delete-asset', { id : data.item.id, file_type : data.item.file_type})">{{ $t('delete') }}</a>
					</div>
				</div>
			</template>
		</b-table>
	</div>
</template>

<script>
import { AlertUtils } from "@/mixins/AlertUtils";
import { exportUtils } from "@/mixins/ExportUtils";

import moment from 'moment';

export default {
	mixins: [AlertUtils, exportUtils],
	props: {
		items : {
			type: Array,
			default : () => {
				return []
			}
		},

		fields : {
			type: Array,
			default : () => {
				return []
			}
		},
	},
	data() {
		return {
			id : null,
			name : null
		}
	},

	methods: {
		truncatedText(text) {
			const maxLength = 50;
			const ellipsis = '...';

			return text.length > maxLength ? text.substring(0, maxLength - ellipsis.length) + '...' : text;
		},
		convertToBytes(num) {
			const number = parseInt(num);
			const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			let unitIndex = 0;
			let size = number;

			while (size >= 1024 && unitIndex < units.length - 1) {
				size /= 1024;
				unitIndex++;
			}

			return `${size.toFixed(2)} ${units[unitIndex]}`;
		},
		getDate(item){
            const date = moment(item);
            let now = moment();
            now = now.add(420, 'minutes')
            const diff = now.diff(date, 'seconds');

            if (diff < 60) {
                return diff + ' seconds ago';
            } else if (diff < 3600) {
                const minutes = Math.floor(diff / 60);
                return minutes + ' minutes ago';
            } else if (diff < 86400) {
                const hours = Math.floor(diff / 3600);
                return hours + ' hours ago';
            } else if (diff < 604800) {
                const days = Math.floor(diff / 86400);
                return days + ' days ago';
            } else if (diff < 2592000) {
                const weeks = Math.floor(diff / 604800);
                return weeks + ' weeks ago';
            } else if (diff < 31536000) {
                const months = Math.floor(diff / 2592000);
                return months + ' months ago';
            } else {
                const years = Math.floor(diff / 31536000);
                return years + ' years ago';
            }
        },
		async downloadImage(id, url, name, type) {
			try {
				const response = await fetch(url)

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const blobImage = await response.blob();

				if(type == 'video') {
					name = name ? name : 'Image';

					if (name.includes('.')) {
						name = name.substring(0, name.lastIndexOf('.'));
					}

					this.exportVidio(blobImage, name ? name : 'Video')
				} else if(type == 'file_group') {
					this.$emit('click:download-all-image', { id, name })
				} else {
					name = name ? name : 'Image';

					if (name.includes('.')) {
						name = name.substring(0, name.lastIndexOf('.'));
					}

					this.exportImage(blobImage, name ? name : 'Image')
				}
				if( type != 'file_group' ) { this.alertSuccess(`${type == 'video' ? 'Video' : 'Image'} successfully downloaded`) }
			} catch (error) {
				console.error('Error during fetch:', error);
				this.alertFail("Failed to download image.")
			}
		},
		useImage(item) {
			if(item.file_type == 'file_group') {
				this.$emit('click:use-folder', item.id)
			} else if(item.file_type == 'video') {
				this.$emit('click:use-video', item)
			} else {
				this.$emit('click:use-image', item.src)
			}
		}
	}
}
</script>

<style scoped>
.map__custom-table {
	color: #8c8c8c;
}

.map__button-action {
	box-shadow: none;
	color: #fff;
}

.map__ba-icon {
	font-size: 16px;
}

.map__img, .map__folder {
	width: 38px;
	height: 38px;
	object-fit: cover;
	border-radius: 6px;
}

.map__folder {
	background-color: #2D2D2D;
}

.map__name-field {
	gap: 8px;
}

.map__small {
	font-size: 10px;
	color: #c4c4c4;
}

.map__stc {
	font-size: 12px;
}
</style>

<style>
.map__custom-table {
	max-height: calc(100vh - 230px);
}

.map__custom-table thead th,  .map__custom-table tbody td{
	border-bottom: 0.1px solid #2d2d2d !important;
}

.map__custom-table .table-b-table-default {
	color: #8c8c8c !important;
	background-color: black !important;
}

.map__custom-table thead tr th {
	top: -1px !important;
}

.map__custom-table tbody td {
	color: white;
	vertical-align: middle;
	font-size: 14px;
}

.map__dropdown .dropdown-menu{
	background: #2D2D2D;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	color: #fff;
	min-width: 100px !important;
	max-width: 100px !important;
}

.map__dropdown .dropdown-item:hover {
	background-color: #8c8c8c;
	color: #fff;
}
</style>