<template>
	<section class="d-flex justify-content-center map" :class="{ 'map--height' : items.length > 0 }">
		<div class="my-assets__page d-flex flex-column container-fluid mt-3">
			<div>
				<h3 class="map__title mb-2">My Assets</h3>
				<h5 class="font-weight-normal">{{ $t('my-assets--desc') }}</h5>
				<div v-if="!isDetail" class="d-flex justify-content-end">
					<button class="map__button-upload btn" @click="openUserDirectory">{{ $t("uploud") }}</button>
				</div>
				<div v-if="!isDetail" class="d-flex align-items-center justify-content-between mt-5">
					<div class="maa__header-tab d-flex">
						<button :disabled="isUpload" v-for="tab in tabs" :key="tab" class="maa__header--button btn" :class="{ 'maa__header--button-active' : tab === activeTab}" @click="activeTab = tab" :title="'Click to see ' + tab">{{ tab.toUpperCase() }}</button>
					</div>
					<div class="uap d-flex align-items-center ml-auto">
						<button :disabled="isUpload" class="ml-2 btn uap--button-display" type="button" title="Display Bars" @click="view = 'tabs'">
							<img v-if="view == 'grid'" src="@/assets/images/icons/ic_bars-nactive.svg" alt="Bars">
							<img v-else src="@/assets/images/icons/ic_bars-active.svg" alt="Bars">
						</button>
						<button :disabled="isUpload" class="ml-1 btn uap--button-display" type="button" title="Display Grid" @click="view = 'grid'">
							<img v-if="view == 'grid'" src="@/assets/images/icons/ic_grid-active.svg" alt="Bars">
							<img v-else src="@/assets/images/icons/ic_grid-nactive.svg" alt="Bars">
						</button>
					</div>
				</div>
			</div>
			<!-- <div class="map__storager-header d-flex align-items-center justify-content-between">
				<div class="map__storager-header--inner d-flex align-items-center">
					<img src="@/assets/images/icons/ic_ma-storage.svg">
					<div class="d-flex align-items-start position-relative">
						<h5 class="map__storage-text">4.7 MB / 100 MB</h5>
						<img src="@/assets/images/icons/ic_ma-info.svg" class="ml-1 map__info-icon" @mouseover="onMouseOver" @mouseout="onMouseOut">
						<div class="map__info position-absolute p-3 ml-2" :class="{ 'map__info-shown' : isInfoHover }">
							<p class="m-0 map__info-text">We give you the convenience of a free space storage plan, 100MB capacity to manage your essential files and assets. </p>
						</div>
					</div>
				</div>
				<button class="btn map__button-upgrade" @click="$router.push({ name : 'TopUp' })">Upgrade</button>
			</div>
			<div class="progress map__proggress-bar">
				<div class="progress-bar bg-primary" role="progressbar" style="width: 60%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
			</div> -->

			<div v-if="isDetail" class="is__detail d-flex align-items-center mt-3 mb-1">
				<button class="is__detail--button btn p-0" @click="resetData()">
					<font-awesome-icon
						icon="fa-solid fa-chevron-left"
					/>
					{{ folderName }}
				</button>
			</div>
			<template v-if="view == 'tabs'">
				<MapTable :items="items" :fields="fields" @click:download-all-image="handleBeforeDownloadFolder" @click:delete-asset="handleDeleteAsset" @click:rename-asset="handleRenameAsset" @click:use-folder="handleUseFolder" @click:use-image="handleUseImage" @click:use-video="handleUseVideo"/>
			</template>
			<template v-else>
					<div class="overflow-auto mt-2 pb-2" :class="{ 'ma--view-grid' : items.length > 0, 'ma--view-grid-detail' : items.length > 0, 'mt-4 ma--view-grid-wsa' : items.length > 0 && activeTab != $t('all') && activeTab != $t('image') }">
						<div v-for="(data, index) in items" :key="index" class="card vg__card position-relative">
							<img v-if="data.file_type == 'video'" :src="data.video_thumbnail" :alt="data.name" class="img__vg" @click="$emit('click:new-image', data)">
							<div v-else-if="data.file_type == 'file_group'" class="folder__vg d-flex align-items-center justify-content-center" @click="$emit('click:new-image', data)">
								<img src="@/assets/images/icons/ic_stack-file.svg" alt="Folder" class="folder__icon">
							</div>
							<img v-else :src="data.src" :alt="data.name" class="img__vg" @click="$emit('click:new-image', data)">
							<div class="card-body p-2 d-flex flex-column justify-content-end">
								<div class="d-flex align-items-center justify-content-between">
									<p class="card__title m-0 text-truncate" :title="data.name">{{ data.name }}</p>
									<div class="dropdown">
										<button class="btn p-0 d-flex align-items-start" type="button" :id="`${_uid}__dropdown`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<b-icon
												icon="three-dots-vertical"
												style="color: #FFFFFF"
												font-scale="0.8"
											/>
										</button>
										<div class="dropdown-menu">
											<a v-if="data.file_type == 'file_group'" class="dropdown-item cursor-pointer" @click="handleOpenFolder({ id : data.id , name : data.name })">{{ $t('open') }}</a>
											<a class="dropdown-item cursor-pointer" @click="downloadImage(data.id, data.src, data.name, data.file_type)">{{ $t('btn-download') }}</a>
											<a v-if="data.file_type != 'file_group'" class="dropdown-item cursor-pointer" @click="handleRenameAsset({ id : data.id , name : data.name })">{{ $t('change-name') }}</a>
										<a class="dropdown-item cursor-pointer" @click="handleDeleteAsset({ id : data.id, file_type : data.file_type })">{{ $t('delete') }}</a>
										</div>
									</div>
								</div>
								<div class="d-flex aling-items-center justify-content-between mt-1">
									<p v-if="data.file_type != 'file_group'" class="m-0 card__text-detail">{{ data.file_size ? convertToBytes(data.file_size) : '-' }}</p>
									<p v-else class="m-0 card__text-detail">{{ data.total_file ? data.total_file : '-' }} files</p>
									<p class="m-0 card__text-detail">{{ getDate(data.createdAt) }}</p>
								</div>
							</div>
						</div>
					</div>
				</template>

			<div v-if="items.length == 0 && !isGetData" class="map__empty-assets d-flex flex-column align-items-center justify-content-center">
				<p class="map__empty-assets--text m-0">You don't have any assets or files.</p>
				<button class="btn map__button-upload-2" @click="openUserDirectory">{{ $t("uploud") }}</button>
			</div>
		</div>

		<VideoTrimPreview id="vtp-modal" :video="videoTrim" :loading="isTrim" @click:trim-video="handleTrimVideo" @reset:video-trim="videoTrim = null"/>
		<RenameModal content v-model="fileName" @cancel-rename="handleCancelRename" @save-name="handleSaveRenameAsset"/>
		<input ref="myAssetUpload" type="file" style="display: none;" @change="handleFileSelect" accept=".jpg, .png, .mp4, .ppt, .pptx, .pdf">
		<Soca v-if="isGetData || isUpload"/>
	</section>
</template>

<script>
import MapTable from '@/components/my-assets/Table'
import JSZip from 'jszip';
import VideoTrimPreview from '@/components/generate-videos/video-trim-preview';
import RenameModal from '@/components/modal/RenameVoiceModel.vue'
import Soca from '@/components/loading/Soca'
import $ from "jquery"
import moment from 'moment';

import { getAssets, getDetailAssets, deleteAsset, deleteFolder, renameAsset, trimVideos, postVideos, postImageConverter } from "@/services/my-assets/my-assets.service"
import { saveAs } from 'file-saver';
import { AlertUtils } from "@/mixins/AlertUtils";
import { exportUtils } from "@/mixins/ExportUtils";

export default {
	mixins: [AlertUtils, exportUtils],
	components: {
		MapTable,
		RenameModal,
		VideoTrimPreview,
		Soca
	},
	data() {
		return {
			view: 'tabs',
			activeTab : this.$t('all'),
			tabs: [this.$t('all'), this.$t('image'), 'video', this.$t('files')],
			isInfoHover : false,
			isGetData : false,
			isTrim : false,
			isUpload : false,

			folderName : null,
			fileName : null,
			idFile : null,
			videoTrim : null,

			items: [],
			detailAssets: [],
			isDetail: false,
			fields: [
				{
					key : 'name',
					label : this.$t("name"),
				},
				{
					key : 'file_type',
					label : this.$t('tipe'),
					class: 'text-center'
				},
				{
					key : 'file_size',
					label : this.$t('size'),
					class: 'text-center',
				},
				{
					key : 'createdAt',
					label : this.$t('created'),
					class: 'text-center',
				},
				{
					key : 'action',
					label : '',
				},
			],
		}
	},

	watch: {
		activeTab(val){
			this.getMyAssets(val)
		}
	},

	mounted() {
        $("section.map").parent().addClass('pb-0');
		this.getMyAssets('all')
	},

	beforeDestroy() {
		$("section.map").parent().addClass('pb-0');
	},


	methods: {
		handleOpenFolder(val) {
			this.tabActive = 'all'
			this.folderName = val.name
			this.getDetailFileGroup(val.id, "open")
		},
		resetData() {
			this.folderName = null
			this.videoTrim = null
			this.isDetail = false
			this.detailAssets = []
		},
		async downloadImage(id, url, name, type) {
			try {
				const response = await fetch(url)

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const blobImage = await response.blob();

				if(type == 'video') {
					this.exportVidio(blobImage, name ? name : 'Video')
				} else if(type == 'file_group') {
					this.handleBeforeDownloadFolder({id, name});
				} else {
					this.exportImage(blobImage, name ? name : 'Image')
				}
				if( type != 'file_group' ) { this.alertSuccess(`${type == 'video' ? 'Video' : 'Image'} successfully downloaded`) }
			} catch (error) {
				console.error('Error during fetch:', error);
				this.alertFail("Failed to download image.")
			}
		},
		
		convertToBytes(num) {
			const number = parseInt(num);
			const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			let unitIndex = 0;
			let size = number;

			while (size >= 1024 && unitIndex < units.length - 1) {
				size /= 1024;
				unitIndex++;
			}

			return `${size.toFixed(2)} ${units[unitIndex]}`;
		},
		getDate(item){
			const date = moment(item);
			let now = moment();
			now = now.add(420, 'minutes')
			const diff = now.diff(date, 'seconds');

			if (diff < 1) {
				return this.$t('a-few-secs-ago');
			} else if (diff < 60) {
				return diff + ' ' + this.$t('secs-ago');
			} else if (diff < 3600) {
				const minutes = Math.floor(diff / 60);
				return minutes + ' ' + this.$t('minutes-ago');
			} else if (diff < 86400) {
				const hours = Math.floor(diff / 3600);
				return hours + ' ' + this.$t('hours-ago');
			} else if (diff < 604800) {
				const days = Math.floor(diff / 86400);
				return days + ' ' + this.$t('days-ago');
			} else if (diff < 2592000) {
				const weeks = Math.floor(diff / 604800);
				return weeks + ' ' + this.$t('weeks-ago');
			} else if (diff < 31536000) {
				const months = Math.floor(diff / 2592000);
				return months + ' ' + this.$t('months-ago');
			} else {
				const years = Math.floor(diff / 31536000);
				return years + ' ' + this.$t('years-ago');
			}
		},
		onMouseOver() {
			this.isInfoHover = true;
		},
		onMouseOut() {
			this.isInfoHover = false;
		},
		async getMyAssets(type) {
			this.isGetData = true

			if(type == 'gambar'){
				type = 'image'
			}else if(type == 'semua'){
				type = 'all'
			}else if(type == 'dokumen'){
				type = 'files'
			}

			await getAssets(type)
			.then((response) => {
				if(response.status) {
					this.items = []
					let data = response.data ? response.data : []
					data.forEach(img => {
						this.items.push({
							type: 'upload',
							id: img._id,
							name: img.filename,
							src: img.url,
							file_type: img.file_type,
							file_size: img.file_size,
							createdAt: img.createdAt,
							video_thumbnail: img.video_thumbnail || null,
							total_file: img.total_file || null,
							selected: false
						})
					})
					this.isGetData = false
				}
			})
		},
		handleBeforeDownloadFolder(val) {
			this.folderName = val.name
			this.getDetailFileGroup(val.id, "download")
		},
		async getDetailFileGroup(id, action) {
			await getDetailAssets(id)
			.then((response) => {
				if(response.status) {

					if(action != 'download'){
						this.items = []
					}
					let data = response.data ? response.data : []
					let useForGenerate = []
					data.forEach(img => {
						if(action == 'use') {
							this.$emit('use:image', {
								type: 'upload',
								id: img._id,
								name: img.filename,
								src: img.url,
							})
						} else if(action == 'download'){
							this.detailAssets.push({
								type: 'upload',
								id: img._id,
								name: img.filename,
								src: img.url,
								file_type: img.file_type,
								file_size: img.file_size,
								createdAt: img.createdAt,
								selected: false
							})
						}else {
							this.items.push({
								type: 'upload',
								id: img._id,
								name: img.filename,
								src: img.url,
								file_type: img.file_type,
								file_size: img.file_size,
								createdAt: img.createdAt,
								selected: false
							})
							useForGenerate.push({
								image: img.url
							})
						}
					})
					if(action == 'download') { this.handleDownloadFolder() }
					if(action == 'open') { this.isDetail = true }
					if(action == 'use-generate') {
						sessionStorage.setItem('gv::import-file', JSON.stringify(useForGenerate))
						this.$router.push({ name:'Generate-Videos', params: { mode: 'new', id: '1' } })
					}
				}
			})
		},
		async handleDownloadFolder() {
			if(this.detailAssets.length > 0) {
				const zip = new JSZip();

				await Promise.all(
					this.detailAssets.map(async (img) => {
						try {
							const response = await fetch(img.src);
							const blob = await response.blob();
							zip.file(`${img.name}`, blob);
						} catch (error) {
							console.error(`Error fetching image at URL: ${img.src}`, error);
						}
					})
				);

				// Generate ZIP
				await zip.generateAsync({ type: 'blob' }).then((content) => {
					saveAs(content, `${this.folderName || 'Folder'}.zip`);
					this.alertSuccess('Folder successfully downloaded')
				});

			}
		},
		handleDeleteAsset(val) {
			if(val.file_type != 'file_group') {
				deleteAsset(val.id)
				.then((response) => {
					if(response.status) {
						this.getMyAssets(this.activeTab)
						this.alertSuccess("File deleted successfully")
					} else {
						this.alertFailIcon("File failed to delete")
					}
				})
				.catch((error) => {
					this.alertFailIcon("File failed to delete")
					console.error(error)
				})
			} else {
				deleteFolder(val.id)
				.then((response) => {
					if(response.status) {
						this.getMyAssets(this.activeTab)
						this.alertSuccess("Folder deleted successfully")
					} else {
						this.alertFailIcon("Folder failed to delete")
					}
				})
				.catch((error) => {
					this.alertFailIcon("Folder failed to delete")
					console.error(error)
				})
			}
		},
		handleRenameAsset(val) {
			this.idFile = val.id
			this.fileName = val.name.slice(0, val.name.lastIndexOf('.'))
			this.$bvModal.show('rvm-modal')
		},
		handleSaveRenameAsset() {
			let formData = {
				rename : this.fileName
			}

			renameAsset(this.idFile, formData)
			.then((response) => {
				if(response.status) {
					this.getMyAssets(this.activeTab)
					this.alertSuccess('Changes saved successfully')
					this.$bvModal.hide('rvm-modal')
				} else {
					this.alertFailIcon('Changes failed to save')
					this.$bvModal.hide('rvm-modal')
				}
			})
			.catch(error => {
				this.alertFailIcon('Changes failed to save')
				this.$bvModal.hide('rvm-modal')
				console.error(error)
			})
		},
		handleUseFolder(id) {
			this.getDetailFileGroup(id, 'use-generate')
		},
		handleUseImage(val) {
			let image = [
				{
					image: val
				}
			]

			sessionStorage.setItem('gv::import-file', JSON.stringify(image))
			this.$router.push({ name:'Generate-Videos', params: { mode: 'new', id: '1' } })
		},
		handleUseVideo(val) {
			this.videoTrim = val
			this.$bvModal.show('vtp-modal')
		},
		handleTrimVideo(val) {
			this.isTrim = true
			if(val.isTrim) {
				let formData = {
					id_video : this.videoTrim.id,
					start : val.durationStartCalculate || 0,
					end : val.durationEnd,
					save_to_asset : "False"
				}

				trimVideos(formData)
				.then((response) => {
					if(response.status == 200) {
						let usedVideo = {
							type: 'video',
							id: response.data.id_video,
							name: this.videoTrim.name ? this.videoTrim.name : 'Video',
							src: this.videoTrim.video_thumbnail,
							videoDurationOrigin: val.durationEnd - val.durationStartCalculate || 0
						}
						sessionStorage.setItem('gv::use-video', JSON.stringify(usedVideo))
						this.$router.push({ name:'Generate-Videos', params: { mode: 'new', id: '1' } })
						this.$bvModal.hide('vtp-modal')
					}
					this.isTrim = false
				})
			} else {
				let usedVideo = {
					type: 'video',
					id: this.videoTrim.id,
					name: this.videoTrim.name ? this.videoTrim.name : 'Video',
					src: this.videoTrim.video_thumbnail,
					videoDurationOrigin: val.durationEnd - val.durationStartCalculate || 0
				}
				this.isTrim = false
				sessionStorage.setItem('gv::use-video', JSON.stringify(usedVideo))
				this.$router.push({ name:'Generate-Videos', params: { mode: 'new', id: '1' } })
				this.$bvModal.hide('vtp-modal')
			}
		},
		handleCancelRename() {
			this.fileName = null
			this.idFile = null
		},
		openUserDirectory() {
			this.$refs.myAssetUpload.click();
		},
		handleFileSelect() {
			const selectedFiles = event.target.files;
			const allowedFileTypes = ['image', 'video', 'ppt', 'pptx', 'pdf'];
			const maxSizeInBytes = 25 * 1024 * 1024; // 25MB
			const maxSizeInBytesImage = 5 * 1024 * 1024; // 5MB
			const maxSizeInBytesVideo = 20 * 1024 * 1024; // 20MB

			for (let i = 0; i < selectedFiles.length; i++) {
				const file = selectedFiles[i];
				const fileType = file.type.split('/')[0]; // jenis file berdasarkan tipe MIME
				const fileType2 = file.type.split('/')[1]; // jenis file berdasarkan tipe MIME
				const fileSize = file.size;

				if (!allowedFileTypes.includes(fileType) && fileType2 !== 'pdf' && fileType2 !== 'vnd.openxmlformats-officedocument.presentationml.presentation' && fileType2 !== 'vnd.ms-powerpoint') {
					this.alertFailIcon('File Extension not allowed');
				} else if (fileType === 'image' && fileSize > maxSizeInBytesImage) {
					this.alertFailIcon('Image size must less than 5MB');
				} else if (fileType === 'video' && fileSize > maxSizeInBytesVideo) {
					this.alertFailIcon('Video size must less than 20MB');
				} else if (fileSize > maxSizeInBytes) {
					this.alertFailIcon('File size must less than 25MB');
				} else {
					if (fileType === 'video') {
						this.handleUploadVideo(file);
					} else {
						this.postImage(file, 'upload')
					}
				}
			}
		},
		async postImage(file, type) {
			this.isUpload = true
			let typeFile = this.getFileTypeText(file.type)
			let formData = new FormData();
  			formData.append('uploadfile', file);

			try {
				const res = await postImageConverter(formData)

				if(res.status) {
					this.isUpload = false
					this.alertSuccess(`${typeFile} successfully ${type == 'upload' ? 'uploaded' : 'saved'}`)
					await this.getMyAssets(this.activeTab)
				} else {
					this.isUpload = false
					this.alertFailIcon(res.message)
				}

			} catch (error) {
				this.isUpload = false
				console.error(error)
			}
		},
		getFileTypeText(fileType) {
			let fileTypeText = '';

			switch (fileType) {
				case 'image/jpeg':
				case 'image/png':
					fileTypeText = 'Image';
					break;
				case 'video/mp4':
				case 'video/quicktime':
					fileTypeText = 'Video';
					break;
				case 'application/pdf':
					fileTypeText = 'PDF';
					break;
				case 'application/vnd.ms-powerpoint':
				case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
					fileTypeText = 'PPT';
					break;
				default:
					fileTypeText = 'Image';
					break;
			}

			return fileTypeText;
		},
		async handleUploadVideo(file) {
			this.isUpload = true
			let formData = new FormData();
  			formData.append('file', file);

			await postVideos(formData)
			.then((response) => {
				if(response.status == 200) {
					this.alertSuccess('Video successfully uploaded')
					this.getMyAssets(this.activeTab)
				} else if(response.status == 405 || response.response.status == 405) {
					this.alertFailIcon('Video size must less than 20MB');
				}
				this.isUpload = false
			})
			.catch((error) => {
				this.alertFail('Something went wrong')
				this.isUpload = false
				console.error(error)
			})
		},
	}
}
</script>
<style>
.vg__card .dropdown-menu {
	background: #2D2D2D;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.60);
	border-radius: 6px;
	color: #fff;
	min-width: 140px !important;
	max-width: 140px !important;
	font-size: 12px;
}

.vg__card .dropdown-item:hover {
	background-color: #8C8C8C;
}
</style>

<style scoped>
.is__detail--button {
	color: white;
}

.is__detail--button, .is__detail--text {
	font-size: 12px;
}

.img__vg, .folder__vg {
	width: 100%;
	height: 134px;
	object-fit: cover;
	object-position: center center;
	border-radius: 6px 6px 0 0;
}
.card__text-detail {
	font-size: 8px;
	font-weight: 400;
	color: #8c8c8c;
}
.card__title {
	font-size: 10px;
}
.vg__card {
	background-color: #2D2D2D;
	border-radius: 8px;
	max-width: 165px;
	width: 100%;
	object-fit: cover;
	height: 190px;
	object-position: center center;
	margin-bottom: 0.8rem;
}

.ma--view-grid, .ma--view-grid-detail {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 0.6rem;
}

.ma--view-grid, .loading__section {
	height: 20rem;
}

.ma--view-tabs {
	height: 22.8rem;
}

.ma--view-tabs-detail {
	height: 24rem;
}

.ma--view-grid-detail {
	height: 21.2rem;
}

.ma--view-grid-wsa {
	height: 22rem;
}
.maa__header--button {
	font-weight: 400;
	font-size: 12px;
	color: #8C8C8C;
	border: 1px solid #2D2D2D;
	padding: 6px 25px;
	border-radius: 25px;

	transition: background-color 0.25s, border 0.25s;
}

.maa__header--button-active {
	color: white;
	background-color: #6D75F6;
	border: 1px solid #6D75F6;
}

.maa__header--button:not(.maa__header--button-active):hover {
	background-color: #585859;
}

.maa__header-tab {
	gap: 6px;
}

.uap--button-upload {
	background-color: #6D75F6;
	color: white;
	font-size: 12px;
	padding: 6px 18px;
	border: none;

	transition: background-color 0.25s
}

.uap--button-upload:hover {
	background-color: #545EED;
}

.uap--button-display {
	padding: 0;
}
.map--height {
	height: calc(100vh - 80px);
	overflow: hidden
}

.my-assets__page {
	color: #fff;
	text-align: left;
	gap: 16px;
}

.map__title {
	font-size: 24px;
}

.map__subtitle {
	font-size: 16px;
	font-weight: 400;
}

.my-assets__page hr {
	height: 0.1px;
	background-color: #2D2D2D;
}

.map__button-upload {
	border-radius: 4px;
	background-color: #6D75F6;
	color: #fff;
	padding: 5px 20px;

	transition: background-color 0.25s;
}

.map__button-upload:hover {
	background-color: #545EED;
}

.map__storage-text {
	color: #FFF;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.map__storager-header--inner {
	gap: 8px;
}

.map__info {
	left: 100%;
	width: 180px;
	border-radius: 6px;
	background-color: #1F1F1F;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.50);

	opacity: 0;
	visibility: hidden;
	transition: opacity 0.25s, visibility 0.25s;
}


.map__info-shown {
	opacity: 1;
	visibility: visible;
}

.map__info-text {
	color: #FFF;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.map__button-upgrade, .map__button-upload-2 {
	color: #6D75F6;
	font-weight: 600;
	border-radius: 4px;
	border: 1px solid #6D75F6;
}

.map__button-upgrade {
	font-size: 12px;
}

.map__button-upload-2 {
	font-size: 18px;
	padding: 5px 30px;
}

.map__proggress-bar {
	height: 6px;
}

.map__proggress-bar .bg-primary {
	background-color: #6D75F6 !important;
	border-radius: 10px;
}

.map__proggress-bar {
	border-radius: 10px;
	background-color: #2D2D2D;
}

.map__empty-assets {
	border-radius: 6px;
	background: #1F1F1F;
	gap: 26px;
	height: 222px;
}

.map__empty-assets--text {
	color: #FFF;
	text-align: center;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
</style>